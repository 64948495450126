<template>
<section class="container h-100">
    <div class="row h-100 align-items-center">
        <div class="col-12 col-lg-6 text-white">
            <h1 class="text-left font-weight-light mt-5 mt-lg-0">
                Dê o primeiro passo para <b class="font-weight-bold">melhorar a comunicação</b> com os seus clientes!
            </h1>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card my-5">
                <div class="card-body p-5 p-md-5">
                    <h4 class="title-border mb-4 text-left">Cadastre-se agora</h4>

                    <validation-observer ref="registerValidationForm" tag="form">
                        <div class="row py-2">
                            <div class="col-12 my-2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="nome"
                                    rules="required">
                                    <input
                                        type="text"
                                        placeholder="Nome"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.length > 0 }"
                                        v-model="form.name"
                                        :readonly="loading"
                                    />
                                </validation-provider>
                            </div>
                            <div class="col-12 my-2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.length > 0 }"
                                        v-model="form.email"
                                        :readonly="loading"
                                    />
                                </validation-provider>
                            </div>
                            <div class="col-12 my-2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="document"
                                    rules="required|min:14">
                                    <input
                                        type="text"
                                        placeholder="CPF/CNPJ"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.length > 0 }"
                                        v-model="form.document"
                                        :readonly="loading"
                                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                                    />
                                </validation-provider>
                            </div>
                            <div class="col-12 my-2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="phone_1"
                                    rules="required|min:18">
                                    <input
                                        type="text"
                                        placeholder="Telefone"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.length > 0 }"
                                        v-model="form.phone_1"
                                        :readonly="loading"
                                        v-mask="['+## (##) #####-####', '+## (##) ####-####']"
                                    />
                                </validation-provider>
                            </div>
                            <div class="col-12 my-2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="senha"
                                    rules="required|min:8">
                                    <div class="input-group">
                                        <input
                                            :type="showPassword ? 'text' : 'password'"
                                            placeholder="Crie sua senha"
                                            class="form-control"
                                            :class="{ 'is-invalid': errors.length > 0 }"
                                            v-model="form.password"
                                            :readonly="loading"
                                            autocomplete="off">
                                        <span
                                            class="input-group-text"
                                            role="button"
                                            @click="showPassword = !showPassword">
                                            <font-awesome-icon
                                                style="width: 15.5px"
                                                :icon="`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`"
                                            />
                                        </span>
                                    </div>
                                </validation-provider>
                            </div>
                        </div>
                    </validation-observer>

                    <div class="row align-items-center">
                        <div class="col-12 col-lg-auto">
                            <button :disabled="loading" @click="submit" class="btn btn-primary px-5 mr-lg-4 mt-3 w-100 w-lg-auto">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status"></span>
                                <span v-else>Cadastrar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, email } from "@validations";
import api from "@/api";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            showPassword: false,
            required,
            min,
            email,
            form: {
                name: null,
                email: null,
                document: null,
                phone_1: null,
                password: null,
                plan_id: this.$route.query.plan_id ?? null,
                plan_type: this.$route.query.type ?? null
            },
            loading: false
        }
    },
    methods: {
        toast(message, type = 'danger') {
            this.$bvToast.toast(message, {
                title: 'Cadastrar',
                variant: type
            });
        },
        async submit() {
            const validated = await this.$refs.registerValidationForm.validate();

            if(!validated) return;

            this.loading = true;

            api.post('/users/register', this.form)
            .then(response => {
                const { type, message, errors } = response.data;

                if (type === 'success') {
                    return this.$store.dispatch('user/login', response.data.body)
                        .then(() => {
                            this.$router.push('configuracoes/numeros');
                            if (this.form.plan_id === '665e04e9ad67c0caf20f56b2') {
                                this.$root.$bvModal
                                    .msgBoxOk('Cadastre um número de disparo para usar as nossas funcionalidades!', {
                                        title: "Sucesso!",
                                        okTitle: "Entendi",
                                        centered: true
                                    })
                                    .then(() => {
                                    location.reload(); // Força a atualização da página mais infos issue 699
                                });
                            }
                        })
                        .catch(error => {
                            console.error('Erro ao fazer login:', error);
                            this.$bvModal.msgBoxOk('Ocorreu um erro ao fazer login.', {
                                    title: "Erro!",
                                    okTitle: "OK",
                                    okVariant: 'danger',
                                    centered: true
                                });
                        });
                }

                this.toast(errors.length > 0 ? errors[0] : message)
            })
            .catch(error => {
                let message = 'Não foi possível processar sua requisição corretamente. Tente novamente mais tarde.';

                if (error.response) {
                    const { errors } = error.response.data;
                    message = errors.length > 0 ? errors[0] : error.response.data.message;
                }
                return this.toast(message)
            })
            .finally(() => this.loading = false)
        }
    }
}
</script>
